import { Input } from "antd";
import { ErrorMessage } from "formik";
import { memo } from "react";

export const useFormBase = () => {

    const FormError = memo(({ children }) => {
        return (
            <div className="text-danger">
                <small>
                    <i className="fa-solid fa-circle-exclamation me-1"></i>
                    {children}
                </small>
            </div>
        );
    })

    const FormTextField = memo(({formik, label, id, type="text", required= false}) => {
        return (
            <>
                <label className={required? "form-required" : ""} htmlFor={`form-tf-${id}`}>{label}</label>
                <Input
                    id={`form-tf-${id}`}
                    type={type}
                    value={formik.values[id]}
                    onChange={(e) => formik.setFieldValue(id, e.target.value)}
                />
                <ErrorMessage name={id} component={FormError} />
            </>
        );
    })

    const FormNumberField = memo(({formik, label, id, required=false, min=0, max=10000000}) => {
        return (
            <>
                <label className={required? "form-required" : ""} htmlFor={`form-tf-${id}`}>{label}</label>
                <Input
                    id={`form-tf-${id}`}
                    type="number"
                    min={min}
                    max={max}
                    step="any"
                    value={formik.values[id]}
                    onChange={(e) => formik.setFieldValue(id, e.target.value)}
                />
                <ErrorMessage name={id} component={FormError} />
            </>
        );
    })

    const FormTextArea = memo(({formik, label, id, type="text", required= false}) => {
        return (
            <>
                <label className={required? "form-required" : ""} htmlFor={`form-tf-${id}`}>{label}</label>
                <textarea
                    id={`form-tf-${id}`}
                    name={`${id}`}
                    className="form-control form-control-sm"
                    type={type}
                    value={formik.values[id] ?? ""}
                    onChange={(e) => formik.setFieldValue(id, e.target.value)}
                ></textarea>
                <ErrorMessage name={id} component={FormError} />
            </>
        );
    })

    const FormPasswordField = memo(({formik, label, id, required = false}) => {
        return (
            <>
                <label className={required? "form-required" : ""} htmlFor={`form-tf-${id}`}>{label}</label>
                <Input.Password
                    id={`form-tf-${id}`}
                    value={formik.values[id]}
                    onChange={(e) => formik.setFieldValue(id, e.target.value)}
                />
                <ErrorMessage name={id} component={FormError} />
            </>
        );
    })

    return {
        FormError,
        FormTextField,
        FormPasswordField,
        FormTextArea,
        FormNumberField
    }
}
