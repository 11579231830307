require('./bootstrap');
import './components/suplies/Main';
import './components/products/Main';
import './components/fixed-assets/Main';
import './components/expenses/Main';

import './components/persons/Main';
import './components/branch-users/Main';

import './components/entries/Main';

import './components/sales/Main';
import './components/buys/Main';

import './components/cash-register-logs/Main';
import './components/cash-register-logs/Main2';
