import { ApiConnector } from "common/ApiConnector"
import { App, Tooltip } from "antd";
import { useFormBase } from "ComponentHooks/useFormBase";
import { useModalBase } from "ComponentHooks/useModalBase";
import { sprintf } from "sprintf-js";
import { useTableBase } from "ComponentHooks/useTableBase";
import { reducer } from "../Reducers/Reducer";
import { FormComponent } from "./FormComponent";
import { useReducer, useState, useCallback } from "react";
import dayjs from "dayjs";
import { useOpenInWindow } from "use-open-window";
const url_endpoint = "api/cash_register_logs";

export const useCashRegisterLogs = (data) => {
    const modalBase = useModalBase(FormComponent, "Abrir/Cerrar Caja");
    const FormBase = useFormBase();
    const { modal, notification } = App.useApp();
    const tableBase = useTableBase();
    const [items, dispatch] = useReducer(reducer, []);
    const [editID, setEditID] = useState(0);
    const [resources, setResources] = useState({
    });

    const [fechas, setFechas] = useState({
        from_date: dayjs().subtract(2, 'weeks').format('YYYY-MM-DD'),
        to_date: dayjs().format('YYYY-MM-DD'),
    });

    const columns = [
        {
            title: '*',
            dataIndex: 'id',
            key: 'buttons',
            width: 95,
            align: 'center',
            render: (value, row) => (
                <>
                    {
                        (row.state === "OPEN")? (
                            <>
                                <button className="btn btn-sm btn-danger py-0 px-3"
                                    onClick={() => editItem(row)}
                                >
                                    <Tooltip title="Cerrar caja">
                                        {/* <i className="fas fa-xmark me-1"></i> */}
                                        <b>Cerrar</b>
                                    </Tooltip>
                                </button>
                                {/* <button className="btn btn-sm btn-danger py-0 px-2"
                                    onClick={() => deleteItem(value)}
                                >
                                    <i className="fas fa-trash"></i>
                                </button> */}
                            </>
                        ) : (<></>)
                    }
                </>
            )
        },
        {
            title: 'ESTADO',
            dataIndex: 'state',
            key: 'state',
            width: 100,
            align: 'center',
            render: (value => {
                if (value === "OPEN") { return (<b>ABIERTO</b>);}
                if (value === "CLOSED") { return (<>CERRADO</>);}
            })
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60,
            className: 'fw-bold',
            align: 'center',
            render: (value) => (<>{sprintf('%04d', value)}</>)
        },
        {
            title: 'Usuario',
            dataIndex: 'user',
            key: 'username',
            width: 100,
            align: 'center',
            render: (value) => (<>{value.name}</>),
            filterDropdown: tableBase.getColumnSearchProps('username').filterDropdown,
            onFilterDropdownOpenChange: tableBase.getColumnSearchProps('username').onFilterDropdownOpenChange,
        },
        {
            title: 'APERTURA',
            dataIndex: 'open_date',
            key: 'open_date',
            width: 200,
            align: 'left',
            render: (value, row) => (
                <div>
                    <b className="me-2">Fecha: </b>{row.open_date}<br/>
                    <b className="me-2">Monto({data.moneda}): </b>
                    {
                        (row.start_comments)? (
                            <Tooltip title={row.start_comments}>
                                <span className='text-decoration-underline'
                                    style={{cursor: 'pointer'}}>
                                    {row.start_amount}
                                </span>
                            </Tooltip>
                        ) : (
                            <span>{row.start_amount}</span>
                        )
                    }
                </div>
            )
        },
        {
            title: 'CIERRE',
            dataIndex: 'close_date',
            key: 'close_date',
            width: 200,
            align: 'left',
            render: (value, row) => (
                <>
                {
                    (row.close_date)? (
                        <div>
                            <b className="me-2">Fecha: </b>{row.close_date}<br/>
                            <b className="me-2">Monto({data.moneda}): </b>
                            {
                                (row.close_comments)? (
                                    <Tooltip title={row.close_comments}>
                                        <span className='text-decoration-underline'
                                            style={{cursor: 'pointer'}}>
                                            {row.close_amount}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <span>{row.close_amount}</span>
                                )
                            }
                            {
                                (row.close_amount === row.expected_amount) ? (
                                    <i className="fas fa-arrow-left ms-1 text-success"></i>
                                ) : (<></>)
                            }
                            {
                                (row.close_amount > row.expected_amount) ? (
                                    <i className="fas fa-arrow-up ms-1 text-primary"></i>
                                ) : (<></>)
                            }
                            {
                                (row.close_amount < row.expected_amount) ? (
                                    <i className="fas fa-arrow-down ms-1 text-danger"></i>
                                ) : (<></>)
                            }
                        </div>
                    ) : (<>-</>)
                }
                </>
            )
        },
        {
            title: `Montos(${data.moneda}) Ingreso / Salida`,
            dataIndex: 'in_amount',
            key: 'in_amount',
            width: 80,
            align: 'center',
            render: (value, row) => (
                <>
                    <span className="text-success">{row.in_amount}</span> / <br/><span className="text-danger">{row.out_amount}</span>
                </>
            )
        },
        {
            title: `Monto esperado (${data.moneda})`,
            dataIndex: 'expected_amount',
            key: 'expected_amount',
            width: 100,
            align: 'center'
        },
    ];

    const handleTableChange = useCallback(
        (pagination, filters, sorter, extra) => { fillItems({ filters, sorter, pagination }) },
        []);

    const fillItems = useCallback(async (params = tableBase.tableParams) => {
        const { columnKey: column, order } = params.sorter;
        const { current: page, pageSize } = params.pagination;

        try {
            tableBase.loading();
            let { data } = await ApiConnector.get(`${url_endpoint}`, {
                params: {
                    filters: params.filters,
                    sorter: { column, order },
                    pagination: { pageSize, page },
                    fechas: fechas
                }
            });
            let { items, total } = data;
            dispatch({ type: "FILL", payload: items });
            tableBase.updateTableParams({
                ...params,
                pagination: { ...params.pagination, total: total }
            });
        } catch (error) { throw new Error(error); }
        finally { tableBase.loaded(); }
    }, [tableBase.tableParams, fechas]);

    const getTableProps = () => tableBase.getTableProps(items, handleTableChange, columns);

    const getItemSelected = () => items.find(it => it.id === editID);

    const newItem = () => { setEditID(0); modalBase.open(); }

    const editItem = (item) => { setEditID(item.id); modalBase.open(); }

    const [handleWindowOpen, newWindowHandle] = useOpenInWindow();

    const saveItem = async (item) => {
        let res = (!item.id) ? await ApiConnector.post(`${url_endpoint}`, item) :
            await ApiConnector.put(`${url_endpoint}/${item.id}`, item);
        await fillItems();
        notification.success({
            description: "Operación completada"
        });

        if(res.status === 200) {
            setEditID(res.data.id);

            let url = `https://erp.quick.com.bo/print_open_cr_log/${res.data.id}`;
            handleWindowOpen(null, {
                url: url,
                centered: true,
                specs: { width: 800, height: 600 }
            });

            if(!data.mode) {
                modalBase.close();
                return res.data.id;
            }
        }

        if(res.status === 204) {
            let url = `https://erp.quick.com.bo/print_close_cr_log/${item.id}`;
            handleWindowOpen(null, {
                url: url,
                centered: true,
                specs: { width: 800, height: 600 }
            });
        }

        location.reload();
        /* if(data.mode) { location.reload(); return; } */
        // modalBase.close();
    }

    const deleteItem = (id) => {
        modal.confirm({
            title: "Confirmación",
            content: "¿Eliminar item?",
            onOk: async () => {
                await ApiConnector.delete(`${url_endpoint}/${id}`);
                fillItems();
            }
        })
    }

    return {
        getTableProps,
        FormBase,
        fillItems,
        getItemSelected,
        newItem,
        saveItem,
        editID,
        modalBase,
        fechas,
        setFechas,
        FormComponent
    };

}
