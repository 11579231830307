import { Modal } from "antd";
import { useState, useCallback } from "react"

export const useModalBase = (Component, title = "title-modal", params = {}) => {
    const [opened, setOpened] = useState(false);
    const open = useCallback(() => { setOpened(true); }, []);
    const close = useCallback(() => { setOpened(false); }, []);

    return {
        open,
        close,
        contextModal: (
            <Modal
                title={title}
                open={opened}
                onCancel={close}
                footer={null}
                destroyOnClose={true}
            >
                <Component {...params} />
            </Modal>
        )
    }
}

export const useModalMediumBase = (Component, title = "title-modal", params = {}) => {
    const [opened, setOpened] = useState(false);
    const open = useCallback(() => { setOpened(true); }, []);
    const close = useCallback(() => { setOpened(false); }, []);

    return {
        open,
        close,
        contextModal: (
            <Modal
                width={1000}
                title={title}
                open={opened}
                onCancel={close}
                footer={null}
                destroyOnClose={true}
            >
                <Component {...params} />
            </Modal>
        )
    }
}
