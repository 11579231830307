import axios from "axios";

export const defaultURL = "/"

export const connector = axios.create({
    baseURL : defaultURL,
    withCredentials: true,
    headers: { Accept : "application/json" },
})

connector.interceptors.response.use(
    response => response, 
    error => {
        if(error.response.status === 401) {
            //window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);