import { connector } from "../common/connector";

export const defaultItem = {
    id: 0,
    name: "",
    description: "",
    measure: "UND",
    status: true,
    values_sum_quantity: 0,
    values_avg_value: 0,

}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FILL':
            return action.payload;

        case 'ADD':
            return [...state, action.payload];
        
        case 'UPDATE':
            return state.map(it => {
                if(it.id === action.payload.id) { return action.payload; }
                else { return it; }
            });
        
        case 'REMOVE':
            return state.filter(it => it.id != action.payload);
        
        default: return state;
    }
}

export const getItems = (params) => {
    return connector.get('api/activos-fijos', { params: params });
}

export const createItem = (data) => {
    return connector.post('api/activos-fijos', data);
}

export const updateItem = (data) => {
    return connector.put(`api/activos-fijos/${data.id}`, data)
}

export const deleteItem = (id) => {
    return connector.delete(`api/activos-fijos/${id}`);
}