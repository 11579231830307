import React, { useRef } from "react";
import { createRoot } from 'react-dom/client';
import ErrorBox from "../common/ErrorBox";
import TableComponent from "./TableComponent";
import { useState, useEffect } from "react";
import { App, Modal } from 'antd';
import { EditableContextProvider } from "../common/EditableContextProvider";
import { useBuys } from "./useBuys";
import { moneda as getMoneda } from "../common/settings";

export default function Main() {
    const buys = useBuys();
    const container_div = document.querySelector("#buys-main");
    const handle_refresh_event = useRef(() => buys.fillItems());
    const moneda = getMoneda();

    useEffect(() => {
        window.refresh_event = new Event('refresh_event');
        container_div.addEventListener('refresh_event', handle_refresh_event.current)

        buys.fillItems();

        return () => {
            container_div.removeEventListener('refresh_event', handle_refresh_event.current);
        }
    }, []);

    return (
        <div>
            <EditableContextProvider data={{
                buys,
                moneda
            }}>
                <div className='my-1 py-1 row'>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md">
                                <span>De:</span>
                                <input type="date" name="date_start" id="date_start" className="form-control form-control-sm"
                                    value={buys.fechas.date_start ?? ""}
                                    onChange={(e) => { buys.setFecha('date_start', e.target.value) }}
                                />
                            </div>
                            <div className="col-md">
                                <span>Hasta:</span>
                                <input type="date" name="date_start" id="date_start" className="form-control form-control-sm"
                                    value={buys.fechas.date_end ?? ""}
                                    onChange={(e) => { buys.setFecha('date_end', e.target.value) }}
                                />
                            </div>
                            <div className="col-md-auto">
                                <span className="text-white d-block">·</span>
                                <button className="btn btn-sm btn-secondary px-3 w-100"
                                    onClick={() => buys.sendFechas()}
                                >
                                    Buscar
                                </button>
                            </div>
                            <div className="col-md-auto">
                                <span className="text-white d-block">·</span>
                                <button className="btn btn-sm btn-light border" type="button"
                                    onClick={() => buys.downloadCSV()}>
                                    <span className="fas fa-table me-1"></span>
                                    Descargar CSV
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                    </div>
                    <div className="col-md-auto">
                        ´<p className="h5">
                            Total Periodo:
                            <b>({moneda}) {buys.totalPeriodo()}</b>
                        </p>
                    </div>
                </div>
                <TableComponent />
            </EditableContextProvider>
        </div>
    );
}

let main = document.getElementById('buys-main');
if (main) {
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <App>
                    <Main />
                </App>
            </ErrorBox>
        </React.StrictMode>
    );
}
