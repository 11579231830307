import { useEffect, useReducer, useState } from "react";
import { getItems, reducer, getCSV, undo } from "./functions";
import dayjs from "dayjs";
import jsdownload from 'js-file-download';
import { App } from "antd";

export const useBuys = () => {
    const { modal } = App.useApp();
    const [items, dispatch] = useReducer(reducer);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: { "current": 1, "pageSize": 20, "total": 0 },
        filters: {},
        sorter: {},
    });
    const [fechas, setFechas] = useState({
        date_start: dayjs().startOf('month').format('YYYY-MM-DD'),
        date_end: dayjs().format('YYYY-MM-DD'),
    });

    const fillItems = async (params = tableParams, fechasParams = fechas) => {
        try {
            setLoading(true);
            let { data } = await getItems({
                filters: params.filters,
                sorter: {
                    column: params.sorter.columnKey,
                    order: params.sorter.order
                },
                pagination: {
                    pageSize: params.pagination.pageSize,
                    page: params.pagination.current
                },
                fechas: fechasParams
            });

            let { items, total, page } = data;
            dispatch({ type: "FILL", payload: items });

            setTableParams({
                ...params,
                pagination: {
                    ...params.pagination,
                    total: total,
                    current: page
                }
            });
        } catch (error) { /* throw new Error(error); */ }
        finally { setLoading(false); }
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        let params = {
            filters: filters,
            sorter: sorter,
            pagination: pagination
        }
        fillItems(params);
    }

    const totalPeriodo = () => {
        if (items) {
            let total = items?.reduce((prev, current) => {
                if(current.undone) { return prev; }
                return (prev + parseFloat(current.total))
            }, 0);
            return sprintf('%.02f', total);
        } else { return '0'; }
    }

    const setFecha = (key, value) => {
        setFechas(prev => ({
            ...prev,
            [key]: value
        }));
    }

    const sendFechas = () => {
        fillItems();
    }

    const downloadCSV = async () => {
        try {
            let res = await getCSV({
                filters: tableParams.filters,
                sorter: {
                    column: tableParams.sorter.columnKey,
                    order: tableParams.sorter.order
                },
                pagination: {
                    pageSize: tableParams.pagination.pageSize,
                    page: tableParams.pagination.current
                },
                fechas: fechas
            });
            jsdownload(res.data, 'ventas.csv');
        } catch (error) { }
    }

    const saleUndo = (sale_id) => {
        if(modal.confirm({
            title: "Confirmar",
            content: "¿DESHACER COMPRA?",
            onOk : async () => {
                try {
                    await undo(sale_id);
                    dispatch({ type: "UNDO", payload: {id: sale_id} });
                } catch (error) { console.log("error en saleUndo."); }
            }
        }));
    }

    return {
        fillItems,
        items,
        loading,
        tableParams,
        handleTableChange,
        totalPeriodo,
        fechas,
        setFecha,
        sendFechas,
        downloadCSV,
        saleUndo
    };

}
