import { Table } from "antd";
import { useState, useEffect, useRef } from "react";
const { Column } = Table;
import { sprintf } from "sprintf-js";

const TableComponent = ({items, isLoading, tableParams, onTableChange, updateItem, deleteItem}) => {
    const [itemsList, setItemsList] = useState(items);
    const [loading, setLoading] = useState(isLoading);
    const [params, setParams] = useState(tableParams);
    const searchInput = useRef(null);

    useEffect(() => {
        setItemsList(items);
    }, [items]);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setParams(tableParams);
    }, [tableParams]);

    const ActionButtons = ({ item }) => {
        return (
            <>
                <button className="btn btn-sm btn-action btn-light border py-0" title="Editar"
                    onClick={() => updateItem(item)}>
                    <span className="fas fa-pen text-sm" style={{ fontSize: '0.85em' }}></span>
                </button>
                <button className="btn btn-sm btn-action btn-light border py-0 ms-1" title="Eliminar"
                    onClick={() => deleteItem(item.id)}>
                    <span className="fas fa-trash text-sm" style={{ fontSize: '0.85em' }}></span>
                </button>
            </>
        );
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div className="p-1" onKeyDown={e => e.stopPropagation()}>
                <input type="text" className="form-control form-control-sm"
                    ref={searchInput}
                    placeholder='Escribe algo...'
                    value={(selectedKeys[0]) ? selectedKeys[0] : ""}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') { handleSearch(selectedKeys, confirm, dataIndex) }
                    }}
                />
                <div className="mt-1 d-flex justify-content-center">
                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    >
                        <span className="fas fa-search"></span>
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => clearFilters && handleReset(clearFilters, setSelectedKeys, confirm)}
                    >
                        Reset
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => { close(); }}
                    >
                        Salir
                    </button>
                </div>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) { setTimeout(() => searchInput.current?.select(), 100); }
        }
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters, setSelectedKeys, confirm) => {
        clearFilters();
        setSelectedKeys([]);
        confirm();
    };

    const Valorado = ({value}) => {
        let stock = parseFloat(value.values_sum_quantity??0);
        let pu = parseFloat(value.values_avg_value??0);

        let valorado = (stock * pu).toFixed(2);
        return (<>{valorado}</>);
    }

    return (
        <Table
            size="small"
            dataSource={itemsList}
            rowKey='id'
            loading={loading}
            onChange={onTableChange}
            pagination={{ ...params.pagination, position: ['bottomCenter'], showSizeChanger: true }}
            scroll={{ y: 600, x: 'max-content' }}
            bordered
        >
            <Column title="*" dataIndex="id" key="buttons" width={90} className="text-center"
                render={(id, row) => (<ActionButtons item={row} />)}

            />
            <Column title="ID" dataIndex="id" key="id" align="center" className="fw-bold"
                sorter={true}
                width={100}
                render={(value) => sprintf('%04d', value)}
            />
            <Column title="NOMBRE" dataIndex="name" key="name"
                sorter={true}
                width={200}
                filterDropdown={getColumnSearchProps('name').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('name').onFilterDropdownOpenChange}
            />
            <Column title="DESCRIPCION" dataIndex="description" key="description"
                sorter={true}
                width={250}
                filterDropdown={getColumnSearchProps('description').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('description').onFilterDropdownOpenChange}
            />
            

        </Table>
    );
}

export default TableComponent;