import { connector } from "../common/connector";

export const defaultItem = {
    id: 0,
    name: "",
    code: "",
    description: "",
    type: "product",
    status: true,
    price: 0,
    branch_mode: 'TODOS',
    create_suply: false,

}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FILL':
            return action.payload;

        case 'ADD':
            return [...state, action.payload];

        case 'UPDATE':
            return state.map(it => {
                if(it.id === action.payload.id) { return action.payload; }
                else { return it; }
            });

        case 'REMOVE':
            return state.filter(it => it.id != action.payload);

        case 'UNDO':
            return state.map(it => {
                if(it.id === action.payload.id) { return { ...it, undone: 'undone' } }
                else { return it; }
            })

        default: return state;
    }
}

export const getItems = (params) => {
    return connector.get('api/ventas', { params: params });
}

export const getCSV = (params) => {
    return connector.get('api/ventas-csv', {
        params: params,
        responseType: 'blob'
    });
}

export const undo = (sale_id) => {
    return connector.get(`api/ventas-deshacer/${sale_id}`);
}
