import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import { Select, Spin } from 'antd';
import FormError from "../common/FormError";
import { EditableContext } from "../common/EditableContextProvider";

const FormComponent = ({ errors }) => {
    const { editItem, onFormAction } = useContext(EditableContext);
    const [initialValues, setInitialvalues] = useState(editItem);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInitialvalues(editItem);
    }, [editItem]);

    const handleSubmit = async (values, formik) => {
        try {
            setLoading(true);
            await onFormAction(values);
            setInitialvalues(values);
        } catch (e) {
            if (e.code === "REQ_FAIL") {
                if (e.errors.email) { formik.setFieldError("email", e.errors.email[0]); }
            }
        }
        finally { setLoading(false); }
    }

    const validateSchema = Yup.object({
        name: Yup.string()
            .min(3, 'El nombre debe tener al menos 3 letras.')
            .max(150, 'El texto es demasiado largo')
            .required('El campo es obligatorio.'),
        typedoc: Yup.string()
            .required('El campo es obligatorio.'),
        nodoc: Yup.string()
            .required('El campo es obligatorio.')
            .max(20, 'El texto es demasiado largo'),
        type: Yup.string()
            .required('El campo es obligatorio.'),
        address: Yup.string()
            .max(200, 'El texto es demasiado largo'),
        phone: Yup.string()
            .max(100, 'El texto es demasiado largo'),
        email: Yup.string()
            .max(100, 'El texto es demasiado largo'),
    });

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validateSchema}
                enableReinitialize={true}
            >
                {
                    (formik) => (
                        <Form disabled={loading}>
                            <div className="py-1">
                                <span>ID: </span>
                                <span className="fw-bold ms-1">{sprintf('%04d', editItem.id)}</span>
                            </div>
                            <div className="mb-2 row">
                                <div className="col-md">
                                    <label htmlFor="name" className="form-label">
                                        Nombre
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Field id="name" name="name" type="text" className="form-control form-control-sm" />
                                    <ErrorMessage name="name" component={FormError} />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md">
                                    <label htmlFor="typedoc" className="form-label">
                                        TIPO DE DOC.:
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Select
                                        className="w-100"
                                        options={[
                                            { value: "NIT", label: "NIT" },
                                            { value: "CI", label: "CI" },
                                        ]}
                                        value={formik.values.typedoc}
                                        onChange={(value) => formik.setFieldValue('typedoc', value)}
                                        onBlur={() => formik.setFieldTouched('typedoc')}
                                    ></Select>
                                    <ErrorMessage name="typedoc" component={FormError} />
                                </div>
                                <div className="col-md">
                                    <label htmlFor="nodoc" className="form-label">
                                        NUMERO DE DOCUMENTO:
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Field id="nodoc" name="nodoc" type="text" className="form-control form-control-sm" />
                                    <ErrorMessage name="nodoc" component={FormError} />
                                </div>
                                <div className="col-md">
                                    <label htmlFor="type" className="form-label">
                                        TIPO:
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Select
                                        className="w-100"
                                        options={[
                                            { value: "CLIENTE", label: "CLIENTE" },
                                            { value: "PROVEEDOR", label: "PROVEEDOR" },
                                        ]}
                                        value={formik.values.type}
                                        onChange={(value) => formik.setFieldValue('type', value)}
                                        onBlur={() => formik.setFieldTouched('type')}
                                    ></Select>
                                    <ErrorMessage name="type" component={FormError} />
                                </div>
                            </div>

                            <div className="mb-2">
                                <label htmlFor="address" className="form-label">
                                    DIRECCION:
                                </label>
                                <Field id="address" name="address" type="text" className="form-control form-control-sm" />
                                <ErrorMessage name="address" component={FormError} />
                            </div>

                            <div className="row mb-2">
                                <div className="col-md">
                                    <label htmlFor="phone" className="form-label">
                                        CELULAR/TELF:
                                    </label>
                                    <Field id="phone" name="phone" type="text" className="form-control form-control-sm" />
                                    <ErrorMessage name="phone" component={FormError} />
                                </div>
                                <div className="col-md">
                                    <label htmlFor="email" className="form-label">
                                        EMAIL:
                                    </label>
                                    <Field id="email" name="email" type="text" className="form-control form-control-sm" />
                                    <ErrorMessage name="email" component={FormError} />
                                </div>
                            </div>

                            <div className="mb-2 d-flex justify-content-between">
                                <div>
                                    <span className="text-danger">(*) </span>
                                    <u>Campo obligatorio.</u>
                                </div>
                                <button type="submit" className="btn btn-sm btn-light border border-secondary px-4">
                                    {
                                        (loading) ? (
                                            <Spin size="small" />
                                        ) : "Guardar"
                                    }
                                </button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
}

export default FormComponent;