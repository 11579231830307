import { Col, Table } from "antd";
import { useState } from "react";
const { Column } = Table;
import { useRef } from "react";

const TableEntries = ({ items, loading, btnData }) => {
    const searchInput = useRef(null);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div className="p-1" onKeyDown={e => e.stopPropagation()}>
                <input type="text" className="form-control form-control-sm"
                    ref={searchInput}
                    placeholder='Escribe algo...'
                    value={(selectedKeys[0]) ? selectedKeys[0] : ""}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') { handleSearch(selectedKeys, confirm, dataIndex) }
                    }}
                />
                <div className="mt-1 d-flex justify-content-center">
                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    >
                        <span className="fas fa-search"></span>
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => clearFilters && handleReset(clearFilters, setSelectedKeys, confirm)}
                    >
                        Reset
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => { close(); }}
                    >
                        Salir
                    </button>
                </div>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) { setTimeout(() => searchInput.current?.select(), 100); }
        }
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters, setSelectedKeys, confirm) => {
        clearFilters();
        setSelectedKeys([]);
        confirm();
    };

    const ActionButtons = ({ id, btnData }) => {

        return (
            <div>
                <button className="btn btn-sm btn-light border py-0 my-0"
                    onClick={() => btnData.action(id)}
                >
                    {btnData.label}
                </button>
            </div>
        );
    }

    return (
        <Table
            size="small"
            dataSource={items}
            rowKey='id'
            loading={loading}
            scroll={{ y: 600, x: 'max-content' }}
            bordered
            pagination={{ pageSize: 20 }}
        >
            <Column title="TIPO" dataIndex="type"
                width={110}
                sorter={(a, b) => a.type.localeCompare(b.type)}
                onFilter={(input, row) => (row.nombre.includes(input))}
                {...getColumnSearchProps('type')}
            />
            <Column title="CUENTA" dataIndex="name"
                width={200}
                sorter={(a, b) => a.name.localeCompare(b.name)}
                {...getColumnSearchProps('name')}
            />
            <Column title="" dataIndex="id"
                width={100}
                align="center"
                render={(value) => (
                    <ActionButtons
                        id={value}
                        btnData={btnData}
                    />
                )}

            />

        </Table>
    );
}

export default TableEntries;