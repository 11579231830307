import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import { Select, Spin } from 'antd';
import FormError from "../common/FormError";
import { EditableContext } from "../common/EditableContextProvider";

const FormComponent = ({ errors }) => {
    const { editItem, onFormAction, resources } = useContext(EditableContext);
    const [initialValues, setInitialvalues] = useState(editItem);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInitialvalues(editItem);
    }, [editItem]);

    const handleSubmit = async (values, formik) => {
        try {
            setLoading(true);
            await onFormAction(values);
            setInitialvalues(values);
        } catch (e) {
            if (e.code === "REQ_FAIL") {
                if (e.errors.email) { formik.setFieldError("email", e.errors.email[0]); }
            }
        }
        finally { setLoading(false); }
    }

    const validateSchema = Yup.object({
        branch_id: Yup.number()
            .required('El campo es obligatorio.'),
        user: Yup.object({
            name: Yup.string()
                .min(3, 'El nombre debe tener al menos 3 letras.')
                .max(150, 'El texto es demasiado largo')
                .required('El campo es obligatorio.'),
            login: Yup.string()
                .required('El campo es obligatorio.'),
            password: Yup.string()
                .required('El campo es obligatorio.')
        })
    });

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validateSchema}
                enableReinitialize={true}
            >
                {
                    (formik) => (
                        <Form disabled={loading}>
                            <div className="py-1">
                                <span>ID: </span>
                                <span className="fw-bold ms-1">{sprintf('%04d', editItem.id)}</span>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="name" className="form-label">
                                    Sucursal
                                    <span className="text-danger">(*)</span>
                                </label>
                                <div className="col-md-6">
                                    <Select className="w-100"
                                        options={resources.branchs?.map(br => { return { value: br.id, label: br.name } })}
                                        value={formik.values.branch_id}
                                        onChange={(value) => formik.setFieldValue('branch_id', value)}
                                        onBlur={() => formik.setFieldTouched('branch_id')}
                                    >
                                    </Select>
                                </div>
                                <ErrorMessage name="branch_id" component={FormError} />
                            </div>

                            <div className="mb-2 row">
                                <div className="col-md">
                                    <label htmlFor="user.name" className="form-label">
                                        Nombre
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Field id="user.name" name="user.name" type="text" className="form-control form-control-sm" />
                                    <ErrorMessage name="user.name" component={FormError} />
                                </div>
                                <div className="col-md">
                                    <label htmlFor="user.login" className="form-label">
                                        Correo Eléctronico
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Field id="user.login" name="user.login" type="email" className="form-control form-control-sm" />
                                    <ErrorMessage name="user.login" component={FormError} />
                                </div>
                            </div>

                            <div className="mb-2 row">
                                <div className="col-md">
                                    <label htmlFor="user.password" className="form-label">
                                        Contraseña
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Field id="user.password" name="user.password" type="password" className="form-control form-control-sm" />
                                    <ErrorMessage name="user.password" component={FormError} />
                                </div>
                                <div className="col-md">
                                    <label htmlFor="user.status" className="form-label" >
                                        <Field name="user.status" id="user.status" type="checkbox" className="form-checkbox me-2" />
                                        Activo
                                    </label>
                                    <ErrorMessage name="user.status" component={FormError} />
                                </div>
                            </div>

                            <div className="mb-2">
                                Roles
                                <div className="col-md-6">
                                    <Select
                                        className="w-100"
                                        mode="multiple"
                                        options={resources.roles.map(r => { return { label: r.name, value: r.id }; })}
                                        value={formik.values.user.roles.map(r => parseInt(r))}
                                        onChange={(value) => formik.setFieldValue('user.roles', value)}
                                    //onBlur={formik.setFieldTouched('user.roles')}
                                    ></Select>
                                </div>
                            </div>

                            <div className="mb-2 d-flex justify-content-between">
                                <div>
                                    <span className="text-danger">(*) </span>
                                    <u>Campo obligatorio.</u>
                                </div>
                                <button type="submit" className="btn btn-sm btn-light border border-secondary px-4">
                                    {
                                        (loading) ? (
                                            <Spin size="small" />
                                        ) : "Guardar"
                                    }
                                </button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
}

export default FormComponent;