import { useContext, useEffect, useState, memo, useCallback } from "react";
import { Formik, Form, ErrorMessage } from "formik"
import * as Yup from 'yup';
import { Radio, Select } from "antd";
import { EditableContext } from "common/EditableContextProvider";

export const FormComponent = () => {
    const { context } = useContext(EditableContext);
    const { FormBase } = context;
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const defaultItem = {
        start_amount: 0,
        start_comments: "",
        close_amount: 0,
        close_comments: ""
    }

    useEffect(() => {
        setInitialValues(getInitialValues());
    }, [context.editID]);

    const getInitialValues = useCallback(
        () => {
            let it = context.getItemSelected();
            if (!it) { it = defaultItem; }
            else { it = { ...it }; }
            return it;
        }, [context.editID]);

    const validateSchema = Yup.object({
        start_amount: Yup.number()
            .required('El campo es obligatorio')
            .min(0, "El monto deber ser mayor o igual a 0"),
        start_comments: Yup.string()
            .max('600', 'El texto es demasiado largo.')
            .notRequired(),
        close_amount: Yup.number()
            .required('El campo es obligatorio')
            .min(0, "El monto deber ser mayor o igual a 0"),
        close_comments: Yup.string()
            .max('600', 'El texto es demasiado largo.')
            .notRequired()
    });

    const handleSubmit = async (values, formik) => {
        try {
            setIsLoading(true);
            let id = await context.saveItem(values);
            if (id) {
                formik.setFieldValue('id', id);
                setInitialValues(prev => ({ ...prev, ...values, id: id }));
            } else { setInitialValues(prev => ({ ...prev, ...values })); }

        } catch (error) {
            const { errors } = error.response.data;
            let keys = Object.keys(errors);
            keys.forEach((key) => {
                formik.setFieldError(key, errors[key][0]);
            });
        }
        finally { setIsLoading(false); }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validateSchema}
            enableReinitialize={true}
        >
            {
                (formik) => (
                    <Form>
                        <fieldset disabled={FormBase.isLoading}>
                            {
                                (!formik.values.id)? (
                                    <>
                                        <div className="mb-2">
                                            <FormBase.FormNumberField formik={formik} label="Monto de apertura" id="start_amount" required={true} />
                                        </div>
                                        <div className="mb-2">
                                            <FormBase.FormTextArea formik={formik} label="Comentarios de apertura" id="start_comments" />
                                        </div>
                                    </>
                                ) : (<></>)
                            }

                            {
                                (formik.values.id)? (
                                    <>
                                        <div className="mb-2">
                                            <span className="fw-bold me-1">Monto esperado:</span>
                                            { formik.values.expected_amount }
                                        </div>
                                        <div className="mb-2">
                                            <FormBase.FormNumberField formik={formik} label="Monto de cierre" id="close_amount" required={true} />
                                        </div>
                                        <div className="mb-2">
                                            <FormBase.FormTextArea formik={formik} label="Comentarios de cierre" id="close_comments" />
                                        </div>
                                    </>
                                ) : (<></>)
                            }

                            <div className="mb-2">
                                <div className="row">
                                    <div className="col">
                                        {/* <button type="button" className="btn btn-sm btn-danger w-100"
                                            onClick={() => context.modalBase.close()}
                                        >
                                            Cancelar
                                        </button> */}
                                    </div>
                                    <div className="col">
                                        <button type="submit" className="btn btn-sm btn-dark w-100"
                                            disabled={isLoading /* || !formik.dirty */}
                                        >
                                            {isLoading ? "Guardando..." : (formik.values.id) ? "Cerrar Caja" : "Abrir caja"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </Form>
                )
            }

        </Formik>
    )

}
