import { connector } from "../common/connector";

export const defaultItem = {
    id: 0,
    branch_id: "",
    user: {
        name: "",
        login: "",
        password: "password",
        status: true,
        roles: []
    }, 
}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FILL':
            return action.payload;

        case 'ADD':
            return [...state, action.payload];
        
        case 'UPDATE':
            return state.map(it => {
                if(it.id === action.payload.id) { return action.payload; }
                else { return it; }
            });
        
        case 'REMOVE':
            return state.filter(it => it.id != action.payload);
        
        default: return state;
    }
}

export const getItems = (params) => {
    return connector.get('api/b_usuarios', { params: params });
}

export const createItem = (data) => {
    return connector.post('api/b_usuarios', data);
}

export const updateItem = (data) => {
    return connector.put(`api/b_usuarios/${data.id}`, data)
}

export const deleteItem = (id) => {
    return connector.delete(`api/b_usuarios/${id}`);
}

export const getResources = () => {
    return connector.get('api/b_usuarios/resources');
}