import React from "react";
import { createRoot } from 'react-dom/client';
import ErrorBox from "common/ErrorBox";
import { customTheme } from "common/customTheme";
import { useEffect } from "react";
import { App, ConfigProvider, Breadcrumb, Table } from 'antd';
import { EditableContextProvider } from "common/EditableContextProvider";
import { useCashRegisterLogs } from "./useCashRegisterLogs";

export default function Main({data}) {
    const context = useCashRegisterLogs(data);

    useEffect(() => {
        Promise.all([
            context.fillItems(),
        ]);
    }, []);

    return (
        <div>
            <EditableContextProvider data={{ context }} >
                <div className="mb-2 d-flex justify-content-between">
                    {
                        (data.usertype === "branch_user")? (
                            <button className="btn btn-sm text-white px-4"
                                style={{backgroundColor: '#292'}}
                                onClick={() => context.newItem()}
                            >
                                <i className="fas fa-cash-register me-2"></i>
                                <b>Abrir caja</b>
                            </button>
                        ) : (<></>)
                    }
                    <div>
                        <div className="d-flex">
                            <span className="py-1 me-2" >Desde:</span>
                            <input type="date" className="form-control form-control-sm" name="from_date" id="from_date"
                                value={context.fechas.from_date}
                                onChange={(e) => context.setFechas(prev => ({...prev, from_date: e.target.value}))}
                            />
                            <span className="py-1 ms-3 me-2" >Hasta:</span>
                            <input type="date" className="form-control form-control-sm" name="to_date" id="to_date"
                                value={context.fechas.to_date}
                                onChange={(e) => context.setFechas(prev => ({...prev, to_date: e.target.value}))}
                            />
                            <button className="btn btn-sm mx-2 btn-light border"
                                type="button"
                                onClick={() => context.fillItems()}
                            >
                                Buscar...
                            </button>
                        </div>
                    </div>
                </div>
                <Table {...context.getTableProps()} />
                { context.modalBase.contextModal }
            </EditableContextProvider>
        </div>
    );
}

let main = document.getElementById('cash-register-log-main');
if (main) {
    let data = main.dataset;
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <ConfigProvider theme={customTheme}>
                    <App>
                        <Main data={data} />
                    </App>
                </ConfigProvider>
            </ErrorBox>
        </React.StrictMode>
    );
}
