import { useReducer, useState } from "react";
import { getItems, createItem, updateItem, defaultItem, deleteItem, reducer } from "./functions";

const useInventario = () => {
    const [items, dispatch] = useReducer(reducer);
    const [editItem, setEditItem] = useState(defaultItem);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: { "current": 1, "pageSize": 20, "total": 0 },
        filters: {},
        sorter: {},
    });

    const fillItems = async (params = tableParams) => {
        try {
            setLoading(true);
            let { data } = await getItems(params);
            let { rows, total, current } = data;

            dispatch({ type: "FILL", payload: rows });
            setTableParams(prev => {
                return {
                    ...prev,
                    ...params,
                    pagination: {
                        ...prev.pagination,
                        ...params.pagination,
                        total: total,
                        current: current
                    }
                }
            });
        } catch (error) { throw new Error(error); }
        finally { setLoading(false); }
    }
    const newItem = () => { setEditItem(defaultItem); }

    const saveItem = async (item) => {
        let item_action = (item.id === 0) ? createItem(item) : updateItem(item);
        try {
            let res = await item_action;
            if (item.id === 0) { 
                item.id = res.data.id;
                fillItems();
            } else {
                dispatch({ type: "UPDATE", payload: item });
            }
            setEditItem(item);
            
        } catch ( error) {
            let { status, data } = error.response;
            if(status === 422) {
                let { errors } = data;
                throw { code: 'REQ_FAIL', message: 'Error en la validación.', errors: errors };
            } else {
                throw { code: 'SERVER_ERROR', message: 'Error interno.' };
            }

        } finally { setLoading(false); }
    }

    const selectItem = (item) => {
        setEditItem({...item, password: 'password', estado: Boolean(item.estado)});
    }

    const destroyItem = async (id) => {
        try {
            await deleteItem(id);
            fillItems();
        } catch (error) { console.log(error); }
    }

    return {
        editItem,
        fillItems,
        items,
        selectItem,
        newItem,
        saveItem,
        destroyItem,
        loading,
        tableParams,
    };

}

export default useInventario;