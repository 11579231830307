import React, { useRef } from "react";
import { createRoot } from 'react-dom/client';
import ErrorBox from "../common/ErrorBox";
import TableComponent from "./TableComponent";
import { useState, useEffect } from "react";
import { App, Modal, Select } from 'antd';
import { EditableContextProvider } from "../common/EditableContextProvider";
import usePersons from "./usePersons";
import FormComponent from "./FormComponent";

export default function Main() {
    const { editItem, fillItems, items, selectItem, newItem, saveItem,
        destroyItem, loading, tableParams, setType } = usePersons();
    const [formVisible, setFormVisible] = useState(false);
    const { modal, notification } = App.useApp();

    useEffect(() => {
        fillItems();
    }, []);

    const handleTableChange = (pagination, filters, sorter, extra) => {
        let params = {
            ...tableParams,
            pagination,
            filters: {...filters, type: tableParams.filters.type },
            sorter,
        };
        fillItems(params);
    }
    const handleFormAction = async (item) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (item === editItem) { notification.info({ description: "No hay cambios." }); resolve(); return; }
                await saveItem(item);
                notification.success({ description: "¡Guardado correcto!" });
                resolve();
            } catch (e) {
                if (e.code === "SERVER_ERROR") {
                    notification.error({ description: e.message });
                }
                reject(e);
            }
        });
    }
    const handleDeleteItem = (id) => {
        modal.confirm({
            content: `¿Eliminar ítem (ID: ${id}) ?`,
            onOk: async () => {
                try {
                    await destroyItem(id);
                    notification.info({ message: "¡Item eliminado!" });
                } catch ({ response }) {
                    notification.error({ message: "Ocurrió un error al eliminar el ítem." });
                    console.log(response.data);
                }
            }
        });
    }
    const addNewItem = () => { newItem(); showForm(); }
    const setUpdateItem = (item) => { selectItem(item); showForm(); }
    const showForm = () => { setFormVisible(true); }
    const hideForm = () => { setFormVisible(false); }

    return (
        <div>
            <div className='my-1 py-1 row'>
                <div className="col-md-auto">
                    <button className="btn btn-sm btn-light border"
                        onClick={() => { addNewItem(); }}
                    >
                        <i className="fa-solid fa-plus me-1"></i>
                        Agregar
                    </button>
                </div>
                <div className="col-md">
                    Mostrar: 
                    <Select
                        className="ms-1"
                        style={{ width: '200px' }}
                        value={tableParams.filters.type}
                        options={[
                            { label: "PROVEEDOR", value: "PROVEEDOR" },
                            { label: "CLIENTE", value: "CLIENTE" },
                        ]}
                        onChange={(value) => setType(value)}
                    ></Select>
                </div>
                <div className="col-md-auto">
                    <button className="btn btn-sm btn-light border" type="button"
                        onClick={() => { livewire.emitTo('persons.table', 'get-csv', tableParams.filters.type[0]) }}>
                        <span className="fas fa-table me-1"></span>
                        Descargar CSV
                    </button>
                </div>
            </div>
            <TableComponent
                items={items}
                isLoading={loading}
                tableParams={tableParams}
                onTableChange={handleTableChange}
                updateItem={setUpdateItem}
                deleteItem={handleDeleteItem}
            />

            <Modal title="Formulario"
                open={formVisible}
                onCancel={hideForm}
                destroyOnClose={true}
                footer={null}
                width={900}
            >
                <EditableContextProvider data={{
                    editItem: editItem,
                    onFormAction: handleFormAction
                }}
                >
                    <FormComponent />
                </EditableContextProvider>
            </Modal>
        </div>
    );
}

let main = document.getElementById('persons-main');
if (main) {
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <App>
                    <Main />
                </App>
            </ErrorBox>
        </React.StrictMode>
    );
}
