import { useState, useEffect } from "react";
import { getData, selectEntry } from './functions';
import { App } from "antd";

const useData = () => {
    const { message } = App.useApp();
    const [data, setData] = useState([]);
    const [activos, setActivos] = useState([]);
    const [inactivos, setInactivos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: { "current": 1, "pageSize": 20, "total": 0 },
        filters: {},
        sorter: {},
    });

    useEffect(() => {
        setActivos(data.filter(it => it.selected === true));
        setInactivos(data.filter(it => it.selected === false));
    }, [data]);

    const fillData = async () => {
        try {
            setLoading(true);
            let res = await getData();
            setData(res.data);
        } catch (error) { console.log(error); }
        finally { setLoading(false); }
    }

    const selEntry = async (data) => {
        try {
            setLoading(true);

            let res = await selectEntry(data);
            setData(prev => prev.map(it => {
                if(it.id === data.ac_entries_id) {
                    return {...it, selected: data.selected}
                } else { return it; }
            }));

        } catch (error) {
            setData(prev => ([...prev]));
            message.error("La cuenta contiene información.", 3);
        } finally { setLoading(false); }
    }

    return {
        data,
        fillData,
        selEntry,
        loading,
        activos,
        inactivos,
        tableParams
    };
}

export default useData;