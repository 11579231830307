import React from "react";
import { createRoot } from 'react-dom/client';
import ErrorBox from "../common/ErrorBox";
import { useEffect } from "react";
import { App, ConfigProvider } from 'antd';
import useData from "./useData";
import TableEntries from "./TableEntries";
import { customTheme } from "../common/customTheme";

export default function Main() {
    const { message } = App.useApp();
    const { data, fillData, selEntry, loading, activos, inactivos } = useData();
    useEffect(() => {
        fillData();
    }, []);

    const handleChangeEntry = (entry_id, selected) => {
        selEntry({
            ac_entries_id: entry_id,
            selected: selected
        });
    }

    return (
        <fieldset disabled={loading}>
            <div className="row my-2 vh-100 row-gap-3">
                <div className="col-md">
                    <div>
                        <h5>Cuentas Activas</h5>
                    </div>
                    <TableEntries
                        items={inactivos}
                        loading={loading}
                        btnData={{ 
                            label:  (
                                <>
                                    Activar
                                    <i className="fas fa-arrow-right ms-1"></i>
                                </>
                            ),
                            action: (id) => handleChangeEntry(id, true)
                        }}
                    />
                </div>
                <div className="col-md">
                    <div>
                        <h5>Cuentas Inactivas</h5>
                    </div>
                    <TableEntries
                        items={activos}
                        loading={loading}

                        btnData={{ 
                            label: (
                                <>
                                    <i className="fas fa-xmark me-1"></i>
                                    Desactivar
                                </>
                            ),
                            action: (id) => handleChangeEntry(id, false)
                        }}
                    />
                </div>
            </div>
        </fieldset>
    );
}

let main = document.getElementById('entries-selector');
if (main) {
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <App>
                    <ConfigProvider
                        theme={customTheme}
                    >
                        <Main />
                    </ConfigProvider>
                </App>
            </ErrorBox>
        </React.StrictMode>
    );
}