import { Table, App } from "antd";
import { useState, useEffect, useRef } from "react";
const { Column } = Table;
import { sprintf } from "sprintf-js";
import { moneda } from "../common/settings";

const TableComponent = ({ items, isLoading, tableParams, onTableChange, updateItem, deleteItem, clone }) => {
    const { modal } = App.useApp();
    const [itemsList, setItemsList] = useState(items);
    const [loading, setLoading] = useState(isLoading);
    const [params, setParams] = useState(tableParams);
    const searchInput = useRef(null);

    useEffect(() => {
        setItemsList(items);
    }, [items]);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setParams(tableParams);
    }, [tableParams]);

    const handle_clonar = (id) => {
        modal.confirm({
            content: "¿Clonar?",
            onOk: () => clone(id)
        });
    }

    const ActionButtons = ({ item }) => {
        return (
            <>
                <button type="button" className="btn btn-sm btn-light border" title="Clonar"
                    onClick={() => handle_clonar(item.id)}
                >
                    <i className="fa-regular fa-clone"></i>
                </button>
                <button className="btn btn-sm btn-action btn-light border py-0 ms-1" title="Editar"
                    onClick={() => updateItem(item)}>
                    <span className="fas fa-pen text-sm" style={{ fontSize: '0.85em' }}></span>
                </button>
                <button className="btn btn-sm btn-action btn-light border py-0 ms-1" title="Eliminar"
                    onClick={() => deleteItem(item.id)}>
                    <span className="fas fa-trash text-sm" style={{ fontSize: '0.85em' }}></span>
                </button>
            </>
        );
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div className="p-1" onKeyDown={e => e.stopPropagation()}>
                <input type="text" className="form-control form-control-sm"
                    ref={searchInput}
                    placeholder='Escribe algo...'
                    value={(selectedKeys[0]) ? selectedKeys[0] : ""}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') { handleSearch(selectedKeys, confirm, dataIndex) }
                    }}
                />
                <div className="mt-1 d-flex justify-content-center">
                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    >
                        <span className="fas fa-search"></span>
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => clearFilters && handleReset(clearFilters, setSelectedKeys, confirm)}
                    >
                        Reset
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => { close(); }}
                    >
                        Salir
                    </button>
                </div>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) { setTimeout(() => searchInput.current?.select(), 100); }
        }
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters, setSelectedKeys, confirm) => {
        clearFilters();
        setSelectedKeys([]);
        confirm();
    };


    const Estado = ({ value }) => {
        const estado = Boolean(value);
        return (
            <>
                {

                    (estado) ? (
                        <>
                            <i className="fa-solid fa-caret-right me-1 text-success"></i> Activo
                        </>) :
                        (<>
                            <i className="fa-solid fa-caret-right me-1 text-danger"></i> Inactivo
                        </>)
                }
            </>
        );
    }

    const Tipo = ({ value }) => {

        if (value === 'product') {
            return (
                <>
                    <i className="fa-solid fa-box me-1 text-success"></i> Producto
                </>
            );
        }

        if (value === 'service') {
            return (
                <>
                    <i className="fa-solid fa-gear me-1 text-success"></i> Servicio
                </>
            );
        }

        return (<></>);
    }

    const Margen = ({ value }) => {
        let precio = parseFloat(value.price ?? 0);
        let costo = parseFloat(value.cost ?? 0);
        let margen = (precio - costo).toFixed(2);

        return (<>{margen}</>);
    }

    return (
        <Table
            size="small"
            dataSource={itemsList}
            rowKey='id'
            loading={loading}
            onChange={onTableChange}
            pagination={{ ...params.pagination, position: ['bottomCenter'], showSizeChanger: true }}
            scroll={{ y: 600, x: 'max-content' }}
            bordered
        >
            <Column title="*" dataIndex="id" key="buttons" width={120} className="text-center"
                render={(id, row) => (<ActionButtons item={row} />)}

            />
            {/* <Column title="ID" dataIndex="id" key="id" align="center" className="fw-bold"
                sorter={true}
                width={100}
                render={(value) => sprintf('%04d', value)}
            /> */}

            <Column title="CODIGO PRODUCTO" dataIndex="code" key="code"
                sorter={true}
                width={200}
                filterDropdown={getColumnSearchProps('code').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('code').onFilterDropdownOpenChange}
            />
            <Column title="NOMBRE" dataIndex="name" key="name"
                sorter={true}
                width={250}
                filterDropdown={getColumnSearchProps('name').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('name').onFilterDropdownOpenChange}
            />

            <Column title="DESCRIPCION" dataIndex="description" key="description"
                sorter={true}
                width={250}
                filterDropdown={getColumnSearchProps('description').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('description').onFilterDropdownOpenChange}
            />
            <Column title="ESTADO" dataIndex="status" key="status"
                sorter={true}
                width={100}
                render={(value) => (<Estado value={value} />)}
            />
            <Column title="TIPO" dataIndex="type" key="type"
                sorter={true}
                width={120}
                render={(value) => (<Tipo value={value} />)}
            />

            <Column title={`PRECIO (${moneda()})`} dataIndex="price" key="price"
                sorter={true} align="right"
                width={80}
                render={(value) => (<>{parseFloat(value ?? 0).toFixed(2)}</>)}
            />

            <Column title={`COSTO (${moneda()})`} dataIndex="cost" key="cost"
                sorter={true} align="right"
                width={80}
                render={(value) => (<>{parseFloat(value ?? 0).toFixed(2)}</>)}
            />

            <Column title="MARGEN DE CONTRIBUCION" dataIndex="margen" key="margen"
                sorter={true} align="right"
                width={140}
                render={(value, row) => (<Margen value={row} />)}
            />

            <Column title="INSUMOS" dataIndex="id" key="insumos"
                width={120}
                render={(value, row) => (
                    (row.type === "product")? (
                        <div className="text-center">
                            <span className="fw-bold">[{row.prod_suplies?.length}]</span><br />
                            <button
                                className="btn btn-sm btn-dark"
                                onClick={() => { livewire.emitTo('products.table', 'show_suplies', value) }}>
                                Ver...
                            </button>
                        </div>
                    ) : (<></>)
                )}
            />

        </Table>
    );
}

export default TableComponent;
