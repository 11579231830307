import { useState, useRef, useCallback } from "react";

export const useTableBase = () => {
    const SvgSearchIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
            width={15} height={15} style={{ marginBottom: '2px' }} >
            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
        </svg>
    );

    const [isLoading, setIsLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: { current: 1, pageSize: 10, total: 0 },
        filters: {},
        sorter: {},
    });

    const searchInput = useRef();

    const getColumnSearchProps = useCallback(
        (dataIndex) => {
            const handleSearch = (selectedKeys, confirm, dataIndex) => {
                confirm();
            };
            const handleReset = (clearFilters, setSelectedKeys, confirm) => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
            };

            return {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                    <div className="p-1" onKeyDown={e => e.stopPropagation()}>
                        <input type="text" className="form-control form-control-sm"
                            ref={searchInput}
                            placeholder='Escribe algo...'
                            value={(selectedKeys[0]) ? selectedKeys[0] : ""}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') { handleSearch(selectedKeys, confirm, dataIndex) }
                            }}
                        />
                        <div className="mt-2 d-flex justify-content-center">
                            <button className="btn btn-sm btn-light border mx-1"
                                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            >
                                {SvgSearchIcon}
                            </button>

                            <button className="btn btn-sm btn-light border mx-1"
                                onClick={() => clearFilters && handleReset(clearFilters, setSelectedKeys, confirm)}
                            >
                                Reset
                            </button>

                            <button className="btn btn-sm btn-light border mx-1"
                                onClick={() => { close(); }}
                            >
                                Salir
                            </button>
                        </div>
                    </div>
                ),
                onFilter: (value, record) =>
                    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownOpenChange: (visible) => {
                    if (visible) { setTimeout(() => searchInput.current?.select(), 100); }
                }
            }
        }, [])

    const loaded = useCallback(() => setIsLoading(false), []);
    const loading = useCallback(() => setIsLoading(true), []);
    const updateTableParams = useCallback((params) => setTableParams(params), []);

    const getTableProps = (items, handleTableChange, columns) => {
        return {
            size: "small",
            dataSource: items,
            rowKey: 'id',
            loading: isLoading,
            onChange: handleTableChange,
            pagination: {
                ...tableParams.pagination,
                position: ['bottomLeft'],
                showSizeChanger: true
            },
            scroll: { y: 400, },
            columns: columns,
            footer: () => (<>Total items: <b>{tableParams.pagination.total}</b></>),
            bordered: true
        }
    }


    return {
        loading,
        loaded,
        tableParams,
        getColumnSearchProps,
        updateTableParams,
        getTableProps
    }

}
