import React from "react";
import { createRoot } from 'react-dom/client';
import ErrorBox from "common/ErrorBox";
import { customTheme } from "common/customTheme";
import { useEffect } from "react";
import { App, ConfigProvider, Breadcrumb, Table } from 'antd';
import { EditableContextProvider } from "common/EditableContextProvider";
import { useCashRegisterLogs } from "./useCashRegisterLogs";

export default function Main2({data}) {
    const context = useCashRegisterLogs(data);

    useEffect(() => {
        Promise.all([
            context.fillItems(),
        ]);
        context.newItem();
    }, []);

    return (
        <div>
            <EditableContextProvider data={{ context }} >
                {/* { context.modalBase.contextModal } */}
                <div className="border rounded m-3 py-2 px-3 bg-white shadow">
                    <p className="h5 fw-bold text-center">
                        APERTURA DE CAJA
                    </p>
                    <p className="alert alert-danger py-1">
                        Debe realizar la <b>apertura de Caja</b> antes de continuar...
                    </p>
                    <context.FormComponent />
                </div>
            </EditableContextProvider>
        </div>
    );
}

let main = document.getElementById('cash-register-log-main-notice');
if (main) {
    let data = main.dataset;
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <ConfigProvider theme={customTheme}>
                    <App>
                        <Main2 data={data} />
                    </App>
                </ConfigProvider>
            </ErrorBox>
        </React.StrictMode>
    );
}
