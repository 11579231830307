import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import { Select, Spin } from 'antd';
import FormError from "../common/FormError";
import { EditableContext } from "../common/EditableContextProvider";

const FormComponent = ({errors}) => {
    const { editItem, onFormAction } = useContext(EditableContext);
    const [initialValues, setInitialvalues] = useState(editItem);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInitialvalues(editItem);
    }, [editItem]);

    const handleSubmit = async (values, formik) => {
        try {
            setLoading(true);
            await onFormAction(values);
            setInitialvalues(values);
        } catch (e) { 
            if(e.code === "REQ_FAIL") {
                if(e.errors.email) { formik.setFieldError("email", e.errors.email[0]); }
            }
        }
        finally { setLoading(false); }
    }

    const validateSchema = Yup.object({
        name: Yup.string()
            .min(3, 'El nombre debe tener al menos 3 letras.')
            .max(180, 'El texto es demasiado largo')
            .required('El campo es obligatorio.'),
        description: Yup.string()
            .max(250, 'El texto es demasiado largo')
            .required('El campo es obligatorio.'),
    });

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validateSchema}
                enableReinitialize={true}
            >
                {
                    (formik) => (
                        <Form disabled={loading}>
                            <div className="py-1">
                                <span>ID: </span>
                                <span className="fw-bold ms-1">{sprintf('%04d', editItem.id)}</span>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="name" className="form-label">
                                    Nombre
                                    <span className="text-danger">(*)</span>
                                </label>
                                <Field id="name" name="name" type="text" className="form-control form-control-sm" />
                                <ErrorMessage name="name" component={FormError} />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="description" className="form-label">
                                    Descripción
                                    <span className="text-danger">(*)</span>
                                </label>
                                <Field id="description" name="description" type="text" className="form-control form-control-sm" />
                                <ErrorMessage name="description" component={FormError} />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="measure" className="form-label">
                                    Unidad de Medida
                                    <span className="text-danger">(*)</span>
                                </label>
                                <Select
                                    className="w-100"
                                    options={[
                                        { value: "UND", label: "UNIDAD" },
                                        { value: "GR", label: "GRAMOS" },
                                        { value: "KG", label: "KILOGRAMOS" },
                                        { value: "CM", label: "CENTIMETROS" },
                                        { value: "M", label: "METROS" },
                                        { value: "ML", label: "MILILITROS" },
                                        { value: "L", label: "LITROS" },
                                        { value: "O", label: "OTROS" },
                                    ]}
                                    value={formik.values.measure}
                                    onChange={(value) => formik.setFieldValue('measure', value)}
                                    onBlur={() => formik.setFieldTouched('measure')}
                                ></Select>
                                <ErrorMessage name="measure" component={FormError} />
                            </div>

                            <div className="mb-2">
                                <label htmlFor="status" className="form-label" >
                                    <Field name="status" id="status" type="checkbox" className="form-checkbox me-2" />
                                    Activo
                                </label>
                                <ErrorMessage name="status" component={FormError} />
                            </div>

                            <div className="mb-2 d-flex justify-content-between">
                                <div>
                                    <span className="text-danger">(*) </span>
                                    <u>Campo obligatorio.</u>
                                </div>
                                <button type="submit" className="btn btn-sm btn-light border border-secondary px-4">
                                    {
                                        (loading) ? (
                                            <Spin size="small" />
                                        ) : "Guardar"
                                    }
                                </button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
}

export default FormComponent;