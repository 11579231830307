import axios from "axios"

export const ApiConnector = axios.create({
    baseURL : '/',
    withCredentials: true,
    headers: { Accept : "application/json" },
});

ApiConnector.interceptors.request.use(config => config,
    error => { return Promise.reject(error); }
)

ApiConnector.interceptors.response.use(
    response => response,
    error => {
        if(error.response) {
            if(error.response.status === 401 || error.response.status  === 419) {
                window.location.href = "/login";
            } else { return Promise.reject(error); }
        } else if (error.request) {
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);
